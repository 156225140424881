<template>
  <div>
    <h2 class="mb-4">Продукты</h2>

    <v-sheet class="pa-4" elevation="3">
      <v-simple-table>
        <thead>
          <tr>
            <th>id</th>
            <th>Внутренее имя</th>
            <th>Имя</th>
            <th>Цена</th>
            <th>Интервал</th>
            <th>Период</th>
            <th>Пробный период (дней)</th>
            <th>Публичный</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product of products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.title }}</td>
            <td>{{ product.price | number }} ₽</td>
            <td>{{ product.interval }}</td>
            <td>{{ product.period }}</td>
            <td>{{ product.trialPeriod }}</td>
            <td>{{ product.isPublic | yesNo }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
export default {
  computed: {
    products() {
      return this.$store.state.dictionary.products;
    }
  }
};
</script>
